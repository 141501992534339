import { Component } from '@angular/core';
import { ApplicationsService } from './panel/shared/applications.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cardea-manager';
  showLoader: Observable<any>;

  constructor(private applicationsService: ApplicationsService) {
    this.showLoader = this.applicationsService.isAppdataLoading;
  }
}
