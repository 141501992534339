import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {path: '', loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule)}
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  providers: []
})

export class AppRoutingModule {
}

// export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
//   preloadingStrategy: PreloadAllModules
//   // useHash: true
// });
