import { Injectable, Injector } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class SystemUsersService {
  settings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  selectedApp: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private injector: Injector,
              private http: HttpClient) {

    this.selectedApp.subscribe(app => {
      if (app) {
        localStorage.setItem('externalAppId', app.external_app_id);
        environment.secretKey = app.config.manager_secret_key;
        environment.externalAppIdTB = app.external_app_id;
      }
    });
  }

  getList(params?) {
    return this.http.get(environment.TBApiUrl + 'manager/system_users/', {params});
  }

  getMe(params?) {
    return this.http.get(environment.TBApiUrl + 'manager/system_users/me/', {params});
  }

  getDetail(id) {
    return this.http.get(environment.TBApiUrl + 'manager/system_users/' + id + '/');
  };

  putDetail(id, data) {
    return this.http.put(environment.TBApiUrl + 'manager/system_users/' + id + '/', data);
  };

  deleteDetail(id, data) {
    return this.http.post(environment.TBApiUrl + 'manager/system_users/' + id + '/remove/', data);
  };

}
