import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from './base-model.service';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class TimezoneService extends BaseModelService {
  defaultTimezone: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private injector: Injector) {
    super(injector, 'any/common/v1/time_zones/');
  }
}
