<header [ngClass]="{'extra-height': !production}">
  <div class="fluid-container">
  <div class="environment-info" *ngIf="!production">QA environment</div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" routerLink="/">
        <img class="header-image" [src]="'assets/images/logo-small.png'"
             alt="Cardea Commerce manager Logo">
      </a>

      <div class="targets-menu" *ngIf="user && tokenChecked">
        <a [class.active]="router.isActive('/dashboard/commerce', false)"
           routerLink="/dashboard/commerce">Commerce</a>
        <a *ngIf="cmsUrl && cmsUrl.length" [href]="cmsUrl" target="_blank">CMS</a>
        <a *ngIf="crmUrl && crmUrl.length" [href]="crmUrl" target="_blank">CRM</a>

        <div class="ml-2" *ngIf="systemUserSettings && systemUserSettings?.apps.length >1" style="width:250px;">
          <div>
            <label class="custom-label font-weight-bold" style="font-size:13px; margin-bottom:0">App</label>
            <ng-select [items]="systemUserSettings.apps"
                       class="font-weight-bold"
                       bindLabel="name"
                       bindValue="id"
                       [(ngModel)]="selectedApp"
                       notFoundText="No apps found"
                       [clearable]="false"
                       (ngModelChange)="appChange($event)"
                       placeholder="Application"
            >
            </ng-select>
          </div>
        </div>

        <div class="ml-3 mb-1 d-flex flex-column justify-content-end">
          <div>
            Timezone: {{timezone}}
          </div>
          <div>
            Current Time: {{time | async}}
          </div>
        </div>
      </div>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent" #navbarCollapse>
        <ul class="navbar-nav ml-auto">
          <div class="dropdown-divider" *ngIf="tokenChecked && user"></div>
          <li class="nav-item" *ngIf="tokenChecked && !user">
            <a class="nav-link primary-font-color" routerLink="/account/login" (click)="collapseMenu()">LOGIN</a>
          </li>
          <li class="nav-item" *ngIf="tokenChecked && !user">
            <a class="nav-link primary-font-color" routerLink="/account/register" (click)="collapseMenu()">SIGN UP</a>
          </li>
          <div class="dropdown-divider" *ngIf="tokenChecked && user"></div>
          <li class="nav-item d-lg-none" *ngIf="tokenChecked && user">
            <a class="nav-link" routerLink="/favorites" (click)="collapseMenu()"
               [class.active]="router.isActive('/favorites', false)"
            >Help</a>
          </li>
          <li class="nav-item d-lg-none" *ngIf="tokenChecked && user">
            <a class="nav-link" routerLink="/profile" (click)="collapseMenu()"
               [class.active]="router.isActive('/profile', false)"
            >Settings</a>
          </li>
          <li class="nav-item d-lg-none" *ngIf="tokenChecked && user">
            <a class="nav-link" routerLink="/orders" (click)="collapseMenu()"
               [class.active]="router.isActive('/orders', false)">
              Notifications</a>
          </li>
          <li class="nav-item d-lg-none" *ngIf="tokenChecked && user">
            <a class="nav-link" routerLink="/orders" (click)="collapseMenu()"
               [class.active]="router.isActive('/orders', false)">
              Logout</a>
          </li>


          <li class="nav-item dropdown d-none d-lg-block" *ngIf="tokenChecked && user">
            <div ngbDropdown id="user-dropdown" class="d-inline-block" placement="bottom-right top-right"
                 display="dynamic">
              <button class="btn btn-outline-primary dropdown-button" ngbDropdownToggle>
                <div class="d-flex justify-content-center align-items-center">
                  <div class="user-icon"></div>
                  {{user?.manager_user?.first_name}} {{user?.manager_user?.last_name}}
                </div>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdown-options">
                <a class="nav-link" [routerLink]="['/profile']" ngbDropdownItem (click)="collapseMenu()"
                   [class.active]="router.isActive('/profile', false)">
                  Profile
                </a>
                <a class="nav-link" [routerLink]="['/account/change-password']" ngbDropdownItem (click)="collapseMenu()"
                   [class.active]="router.isActive('/account/change-password', false)">
                  Change Password
                </a>
                <a class="nav-link" [routerLink]="" ngbDropdownItem (click)="collapseMenu(); onLogout();">
                  Logout
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
<!-- https://stackoverflow.com/a/59913860/7666033 -->
