import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from './base-model.service';
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class CommonService extends BaseModelService {
  languages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private injector: Injector) {
    super(injector, 'any/common/v1/');
  }

  getSupportedLanguages() {
    return this.http.get(environment.apiUrl + `any/common/v1/supported_languages/`);
  }

  translator(data) {
    return this.http.post(environment.apiUrl + `manager_user/common/v1/translator/`, data);
  }
}
