import { Pipe, PipeTransform } from '@angular/core';
import format from 'xml-formatter';

@Pipe({
  name: 'xml'
})
export class XmlPipe implements PipeTransform {

  transform(value: string): string {
    return format(value);
  }
}
