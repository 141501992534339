import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { EnumsService } from "../../panel/shared/enums.service";
import { ApplicationsService } from "../../panel/shared/applications.service";
import { TimezoneService } from "../../panel/shared/timezone.service";
import { CommonService } from "../../panel/shared/common.service";
import { takeUntil } from "rxjs/operators";
import { SystemUsersService } from "../../panel/shared/system-users.service";

@Injectable()
export class AuthGuard implements CanActivate {
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private router: Router,
              private enumsService: EnumsService,
              private applicationsService: ApplicationsService,
              private timezoneService: TimezoneService,
              private commonService: CommonService,
              private systemUserService: SystemUsersService,
              private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const promise = new Promise<boolean>((resolve, reject) => {
      const token = JSON.parse(localStorage.getItem('auth'));
      if (!token || !token.hasOwnProperty('api_key') || !token.api_key) {
        this.router.navigate(['/account/login']);
        reject();
      }

      if (this.authService.user.value) {
        resolve(true);
      } else {
        this.applicationsService.isAppdataLoading.next(true);
        this.systemUserService.getMe().pipe(takeUntil(this.unsubscribe$))
          .subscribe((res: any) => {
            this.systemUserService.settings.next(res);
            Promise.all([
              this.authService.me().toPromise(),
              this.enumsService.getEnums().toPromise(),
              this.applicationsService.getCMSSettings().toPromise(),
              this.timezoneService.getList().toPromise(),
              this.commonService.getSupportedLanguages().toPromise()
            ]).then(([me, enums, settings, timezone, languages]) => {
              this.enumsService.enums.next(enums);
              this.authService.user.next(me);
              this.applicationsService.settings.next(settings);
              this.authService.tokenChecked.next(true);
              this.commonService.languages.next(languages);
              this.timezoneService.defaultTimezone.next(timezone.application_default_display_time_zone);
              setTimeout(() => {
                resolve(true);
              });
              this.applicationsService.isAppdataLoading.next(false);
            }).catch(error => {
              localStorage.removeItem('auth');
              this.authService.logout();
              reject(error);
              this.applicationsService.isAppdataLoading.next(false);
            });
          }, error => {
            this.applicationsService.isAppdataLoading.next(false);
          });
      }
    });
    return promise;
  }
}
