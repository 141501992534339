import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { LoaderService } from '../../panel/shared/loader.service';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from "ngx-toastr";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private toastrService: ToastrService
  ) {
  }

  requestCount = 0;

  addToken(request: HttpRequest<any>): HttpRequest<any> {
    const req = request.clone({});
    let headers = req.headers;

    if (req.url.indexOf(environment.apiUrl) !== -1) {
      let auth = JSON.parse(localStorage.getItem('auth'));
      if (!headers.get('PPS-USER-AUTHORIZATION') && auth && auth.hasOwnProperty('api_key') && auth['api_key']) {
        headers = headers.set('PPS-USER-AUTHORIZATION', `ApiKey ${auth['username']}:${auth['api_key']}`);
      }
      if (environment.secretKey) {
        headers = headers.set('PPS-CLIENT-SECRET', environment.secretKey);
      }
      headers = headers.set('PPS-DEVICE-TYPE', environment.deviceType);
      let uuid = localStorage.getItem('uuid') || null;
      if (!localStorage.getItem('uuid')) {
        uuid = uuidv4();
        localStorage.setItem('uuid', uuid);
      }
      headers = headers.set('PPS-DEVICE-ID', uuid);
    }

    if (req.url.indexOf('api/v2/pps/system-users/') === -1) {
      let auth = JSON.parse(localStorage.getItem('auth'));
      if (!headers.get('authorization') && auth && auth.hasOwnProperty('api_key') && auth['api_key']) {
        headers = headers.set('authorization', `ApiKey ${auth['username']}:${auth['api_key']}`);
      }
    }

    if (req.url.indexOf('api/v2/pps/logout/') !== -1 || req.url.indexOf('api/v2/pps/user/me/') !== -1 || req.url.indexOf('user/change_password/') !== -1 || req.url.indexOf('api/v2/pps/signup/') !== -1 || req.url.indexOf('api/v2/pps/forgot_password/') !== -1) {
      let auth = JSON.parse(localStorage.getItem('auth'));
      if (!headers.get('authorization') && auth && auth.hasOwnProperty('api_key') && auth['api_key']) {
        headers = headers.set('authorization', `ApiKey ${auth['username']}:${auth['api_key']}`);
      }
      headers = headers.set('EXTERNAL-APP-ID', environment.externalAppIdTB);
    }

    if (req.url.indexOf('api/v2/pps/login/') !== -1) {
      headers = headers.set('TB-LOGIN-TYPE', 'manager');
    }

    if (headers.get('interceptor-skip-loader')) {
      headers = headers.delete('interceptor-skip-loader');
    }

    return req.clone({headers});
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let error: HttpErrorResponse;

    if (!req.headers.get('interceptor-skip-loader')) {
      ++this.requestCount;
      this.loaderService.show();
    }

    return next.handle(this.addToken(req)).pipe(tap((event: HttpEvent<any>) => {
        lastResponse = event;
      }, (err: any) => {
        error = err;
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            localStorage.removeItem('auth');
            localStorage.removeItem('uuid');
          }

          if (err.status === 500) {
            this.toastrService.error(`Something went wrong with server. Support got email with details and will fix that soon.`);
          }
        }

      }),
      finalize(() => {
        if (((lastResponse.type === HttpEventType.Sent) || lastResponse instanceof HttpResponse) && this.requestCount) {
          --this.requestCount;
          if (this.requestCount <= 0) {
            this.loaderService.hide();
          }
        }
      }));
  }
}

