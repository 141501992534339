import {NgModule} from '@angular/core';
import {TooltipDirective} from "./tooltip.directive";
import {
  PurchasedProductsTooltipComponent
} from "../widgets/purchased-products-tooltip.component/purchased-products-tooltip.component";
import {CommonModule} from "@angular/common";
import {ImageFieldComponent} from "../widgets/image-field/image-field.component";
import {FileUploadModule} from "ng2-file-upload";
import {TabsComponent} from "../widgets/tabs/tabs.component";
import {DateFormatterComponent} from "../widgets/date-formatter/date-formatter.component";
import {NestedProductTableComponent} from "../widgets/nested-product-table/nested-product-table.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {TranslatableFieldComponent} from "../widgets/translatable-field/translatable-field.component";
import {ValdemortModule} from "ngx-valdemort";
import {CKEditorModule} from "ng2-ckeditor";
import {
  NestedExternalTransactionsTableComponent
} from "../widgets/nested-external-transactions-table/nested-external-transactions-table.component";
import {DiscountAmountTooltipComponent} from "../widgets/discount-amount-tooltip/discount-amount-tooltip.component";
import {
  NestedCcTransactionReturnTableComponent
} from "../widgets/nested-cc-transaction-return-table/nested-cc-transaction-return-table.component";
import {
  NestedOfferingUsagesTableComponent
} from "../widgets/nested-offering-usages-table/nested-offering-usages-table.component";
import {NestedScanTableComponent} from "../widgets/nested-scans-table/nested-scan-table.component";
import {
  NestedProductReturnTableComponent
} from "../widgets/nested-product-returns-table/nested-product-return-table.component";
import {
  NestedVcTransactionReturnTableComponent
} from "../widgets/nested-vc-transaction-return-table/nested-vc-transaction-return-table.component";
import {
  NestedExternalTransactionReturnTableComponent
} from "../widgets/nested-external-transaction-return-table/nested-external-transaction-return-table.component";
import {SearchableSelectFieldComponent} from '../widgets/searchable-select-field/searchable-select-field.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {CallbackPipe} from "./callback.pipe";
import {
  NestedReservationsTableComponent
} from '../widgets/nested-reservations-table/nested-reservations-table.component';
import {
  NestedCollaborationRequestAppliesTableComponent
} from '../widgets/nested-collaboration-request-applies-table/nested-collaboration-request-applies-table.component';
import {ValidateInputDirective} from './validate-input.directive';
import {
  NestedExternalTransactionsOperationsTableComponent
} from '../widgets/nested-external-transactions-operations-table/nested-external-transactions-operations-table.component';
import {XmlPipe} from './xml.pipe';
import {MonacoEditorModule} from 'ngx-monaco-editor';
import {CustomColumnWidthDirective} from './custom-column-width.directive';
import {CopyComponent} from '../widgets/copy/copy.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {SafeHtmlPipe} from './safe-html.pipe';
import {EmailPreviewComponent} from '../pages/emails/email-templates/email-preview/email-preview.component';
import {LoaderDirective} from './loader.directive';
import {TwoDecimalPlacesDirective} from './two-decimal-places.directive';
import {OnlyPositiveNumbersDirective} from "./only-positive-numbers.directive";

@NgModule({
  imports: [
    CommonModule,
    FileUploadModule,
    NgxDatatableModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    ValdemortModule,
    CKEditorModule,
    NgSelectModule,
    ClipboardModule,
    MonacoEditorModule
  ],
  declarations: [
    TooltipDirective,
    PurchasedProductsTooltipComponent,
    ImageFieldComponent,
    TabsComponent,
    DateFormatterComponent,
    NestedProductTableComponent,
    NestedCcTransactionReturnTableComponent,
    NestedVcTransactionReturnTableComponent,
    NestedExternalTransactionReturnTableComponent,
    NestedProductReturnTableComponent,
    NestedScanTableComponent,
    NestedOfferingUsagesTableComponent,
    NestedExternalTransactionsTableComponent,
    TranslatableFieldComponent,
    DiscountAmountTooltipComponent,
    SearchableSelectFieldComponent,
    CallbackPipe,
    NestedReservationsTableComponent,
    NestedCollaborationRequestAppliesTableComponent,
    ValidateInputDirective,
    NestedExternalTransactionsOperationsTableComponent,
    XmlPipe,
    CustomColumnWidthDirective,
    CopyComponent,
    SafeHtmlPipe,
    EmailPreviewComponent,
    LoaderDirective,
    TwoDecimalPlacesDirective,
    OnlyPositiveNumbersDirective
  ],
  exports: [
    TooltipDirective,
    PurchasedProductsTooltipComponent,
    ImageFieldComponent,
    TabsComponent,
    DateFormatterComponent,
    NestedProductTableComponent,
    NestedCcTransactionReturnTableComponent,
    NestedVcTransactionReturnTableComponent,
    NestedExternalTransactionReturnTableComponent,
    NestedProductReturnTableComponent,
    NestedScanTableComponent,
    NestedOfferingUsagesTableComponent,
    NestedExternalTransactionsTableComponent,
    TranslatableFieldComponent,
    DiscountAmountTooltipComponent,
    SearchableSelectFieldComponent,
    CallbackPipe,
    NestedReservationsTableComponent,
    NestedCollaborationRequestAppliesTableComponent,
    ValidateInputDirective,
    NestedExternalTransactionsOperationsTableComponent,
    XmlPipe,
    CustomColumnWidthDirective,
    CopyComponent,
    LoaderDirective,
    TwoDecimalPlacesDirective,
    SafeHtmlPipe,
    EmailPreviewComponent,
    OnlyPositiveNumbersDirective
  ]
})
export class SharedModule {
}
