import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { LoaderComponent } from "./panel/widgets/loader/loader.component";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NavbarComponent } from "./panel/widgets/navbar/navbar.component";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ValdemortModule } from "ngx-valdemort";
import { SharedModule } from "./authentication/shared/shared.module";
import { TokenInterceptor } from "./authentication/shared/token.interceptor";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";
import { DaySlotsModalComponent } from "./panel/widgets/day-slots-modal/day-slots-modal.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AddOrEditSlotModalComponent } from "./panel/widgets/add-or-edit-slot-modal/add-or-edit-slot-modal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { AddSlotModalComponent } from "./panel/widgets/add-slot-modal/add-slot-modal.component";
import { CommonModule, DatePipe } from "@angular/common";
import { NgSelectModule } from "@ng-select/ng-select";
import { ConfirmationModalComponent } from "./panel/widgets/confirmation-modal/confirmation-modal.component";
import { SlotDetailsModalComponent } from "./panel/widgets/slot-details-modal/slot-details-modal.component";
import {
  ChangeCompanyRequestStatusModalComponent
} from "./panel/widgets/change-company-request-status-modal/change-company-request-status-modal.component";
import {
  BatchDiscountCodeCreateModalComponent
} from "./panel/widgets/batch-discount-code-create-modal/batch-discount-code-create-modal.component";
import {
  GenerateProductUrlModalComponent
} from "./panel/widgets/generate-product-url-modal/generate-product-url-modal.component";
import { ClipboardModule } from "ngx-clipboard";
import { RefundModalComponent } from "./panel/widgets/refund-modal/refund-modal.component";
import { ResendEmailModalComponent } from "./panel/widgets/resend-email-modal/resend-email-modal.component";
import { GeneralReportsComponent } from "./panel/pages/reports/general-reports/general-reports.component";
import { SharedModule as GeneralSharedModule } from "./panel/shared/shared.module";
import { DailySummaryComponent } from "./panel/pages/reports/daily-summary/daily-summary.component";
import {
  CategoryDailySummaryComponent
} from "./panel/pages/reports/category-daily-summary/category-daily-summary.component";
import { VariantSummaryComponent } from "./panel/pages/reports/variant-summary/variant-summary.component";
import { AddEventModalComponent } from "./panel/widgets/add-event-modal/add-event-modal.component";
import {
  AddProductToEventModalComponent
} from "./panel/widgets/add-product-to-event-modal/add-product-to-event-modal.component";
import {
  EditEventOfferingModalComponent
} from "./panel/widgets/edit-event-offering-modal/edit-event-offering-modal.component";
import { AddUserTagModalComponent } from "./panel/widgets/add-user-tag/add-user-tag-modal.component";
import { ImageListModalComponent } from "./panel/widgets/image-list-modal/image-list-modal.component";
import {
  AddVariantToProductModalComponent
} from "./panel/widgets/add-variant-to-product-modal/add-variant-to-product-modal.component";
import {
  AddUserMembershipModalComponent
} from "./panel/widgets/add-user-membership/add-user-membership-modal.component";
import { BenefitModalComponent } from "./panel/widgets/benefit-modal/benefit-modal.component";
import { AddSlotBatchModalComponent } from "./panel/widgets/add-slot-batch-modal/add-slot-batch-modal.component";
import { ColumnPickerModalComponent } from "./panel/widgets/column-picker-modal/column-picker-modal.component";
import { AddUserAddressModalComponent } from './panel/widgets/add-user-address-modal/add-user-address-modal.component';
import { AddSalesUserModalComponent } from './panel/widgets/add-sales-user-modal/add-sales-user-modal.component';
import { InventoryLoadModalComponent } from "./panel/widgets/inventory-load-modal/inventory-load-modal.component";
import { LoadVirtualCardModalComponent } from "./panel/widgets/load-virtual-card/load-virtual-card-modal.component";
import { CallbackPipe } from "./panel/shared/callback.pipe";
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { XmlPipe } from './panel/shared/xml.pipe';
import {
  ApproveOrRejectEmailChangeRequestModalComponent
} from './panel/widgets/approve-or-reject-email-change-request-modal/approve-or-reject-email-change-request-modal.component';
import {
  CreateChangeEmailRequestModalComponent
} from './panel/widgets/create-change-email-request-modal/create-change-email-request-modal.component';
import { AddInventoryModalComponent } from "./panel/widgets/add-inventory-modal/add-inventory-modal.component";
import { EditInventoryModalComponent } from './panel/widgets/edit-inventory-modal/edit-inventory-modal.component';
import {RefundDetailsModalComponent} from "./panel/widgets/refund-details-modal/refund-details-modal.component";

if (window.location.origin.indexOf('localhost') === -1) {
  Sentry.init({
    dsn: "https://7b44ba089ce64154984ad10d5c8a6dfe@o494649.ingest.sentry.io/5584091",
    environment: environment.production ? 'prod' : 'develop',
    integrations: [
      new Integrations.BrowserTracing({
        // tracingOrigins: ["localhost", "https://yourserver.io/api"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    NavbarComponent,
    DaySlotsModalComponent,
    AddOrEditSlotModalComponent,
    LoadVirtualCardModalComponent,
    AddSlotModalComponent,
    AddSlotBatchModalComponent,
    EditEventOfferingModalComponent,
    GenerateProductUrlModalComponent,
    ConfirmationModalComponent,
    SlotDetailsModalComponent,
    ChangeCompanyRequestStatusModalComponent,
    BatchDiscountCodeCreateModalComponent,
    RefundModalComponent,
    RefundDetailsModalComponent,
    ResendEmailModalComponent,
    GeneralReportsComponent,
    DailySummaryComponent,
    CategoryDailySummaryComponent,
    VariantSummaryComponent,
    AddEventModalComponent,
    AddProductToEventModalComponent,
    AddUserTagModalComponent,
    ImageListModalComponent,
    AddVariantToProductModalComponent,
    AddUserMembershipModalComponent,
    BenefitModalComponent,
    InventoryLoadModalComponent,
    ColumnPickerModalComponent,
    AddUserAddressModalComponent,
    AddSalesUserModalComponent,
    ApproveOrRejectEmailChangeRequestModalComponent,
    CreateChangeEmailRequestModalComponent,
    AddInventoryModalComponent,
    EditInventoryModalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    ToastrModule.forRoot({
      enableHtml: true
    }),
    NgbModule,
    ValdemortModule,
    SharedModule,
    NgxDaterangepickerMd.forRoot(),
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    NgSelectModule,
    ClipboardModule,
    GeneralSharedModule,
    MonacoEditorModule.forRoot()
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    DatePipe,
    XmlPipe
  ],
  exports: [
    CallbackPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
