import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription, timer} from 'rxjs';
import {AuthService} from '../../../authentication/shared/auth.service';
import {Router} from '@angular/router';
import {TimezoneService} from "../../shared/timezone.service";
import {map, takeUntil} from "rxjs/operators";
import {SystemUsersService} from "../../shared/system-users.service";
import {ApplicationsService} from "../../shared/applications.service";
import moment from "moment";
import {environment} from "../../../../environments/environment";

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  unsubscribe$: Subject<void> = new Subject<void>();
  userSubscription: Subscription;
  systemUserSubscription: Subscription;
  tokenCheckedSubscription: Subscription;
  user = {};
  tokenChecked = false;
  subdomainCompany;
  subdomainMode;
  timezone = null;
  cmsUrl = null;
  crmUrl = null;
  systemUserSettings = null;
  selectedApp = null;
  time: Observable<any>;
  production = null;

  constructor(
    private authService: AuthService,
    public router: Router,
    private timezoneService: TimezoneService,
    private systemUserService: SystemUsersService,
    private applicationsService: ApplicationsService,
  ) {
    this.production = environment.production;
    // console.log("this.applicationsSettings.settings.value", this.applicationsService.settings.value);

    this.applicationsService.settings.subscribe(x => {
      if (!x)
        return
      this.cmsUrl = x.cms_url;
      this.crmUrl = x.crm_url;
    }, error => {
      console.log("error", error);
    });

    this.userSubscription = this.authService.user.subscribe(data => {
      this.user = data;
    });
    this.systemUserSubscription = this.systemUserService.settings.subscribe(data => {
      this.systemUserSettings = data;
      if (this.systemUserSettings && this.systemUserSettings.apps && this.systemUserSettings.apps.length) {
        let externalAppId = localStorage.getItem('externalAppId');
        let app = null;
        if (externalAppId && +externalAppId) {
          app = this.systemUserSettings.apps.find(x => +x.external_app_id === +externalAppId)
        }
        if ((!externalAppId || !+externalAppId) || !app) {
          app = this.systemUserSettings.apps[0];
        }
        this.systemUserService.selectedApp.next(app);
        this.selectedApp = app.id;
      }
    });

    this.tokenCheckedSubscription = this.authService.tokenChecked.subscribe(data => {
      this.tokenChecked = data;
    });

    this.timezoneService.defaultTimezone
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(x => {
        this.timezone = x;

        if (this.timezone) {
          this.time = timer(0, 1000)
            .pipe(map(() => moment.tz(this.timezone).format('H:mm A')));
        }
      });

  }

  onLogout() {
    this.authService.logout();
  }

  ngOnInit() {
  }

  collapseMenu() {
    $('.navbar-collapse').collapse('hide');
  }

  appChange(data) {
    const selectedApp = this.systemUserSettings.apps.find(x => +x.id === +data);
    this.systemUserService.selectedApp.next(selectedApp);
    this.selectedApp = data;
    this.router.navigate([','], {queryParams: {}});
    setTimeout(() => {
      window.location.reload();
    })
  }

  ngOnDestroy() {
    this.userSubscription && this.userSubscription.unsubscribe();
    this.tokenCheckedSubscription && this.tokenCheckedSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
