import { Injectable, Injector } from '@angular/core';
import { BaseModelService } from './base-model.service';
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class EnumsService extends BaseModelService {
  enums: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private injector: Injector) {
    super(injector, '');
  }

  getEnums(params?) {
    return this.http.get(environment.apiUrl + 'any/common/v1/manager_enums', { params });
  }

  getRawEnumValues(keys) {
    let data = this.enums.value;
    if (!data) return;

    let error = false;
    keys.forEach(key => {
      if (data.hasOwnProperty(key)) {
        data = data[key];
      } else {
        error = true;
      }
    });
    return !error ? data : null;
  }

  getArray(keys) {
    let enumValue = this.getRawEnumValues(keys);
    if (!enumValue) return [];
    let ar = [];
    enumValue.forEach(x => {
      ar.push({ id: x[0], name: x[1] });
    });
    return ar;
  }

  getObject(keys) {
    let enumValue = this.getRawEnumValues(keys);
    if (!enumValue) return {};
    let obj = {};
    enumValue.forEach(x => {
      obj[x[0]] = x[1];
    });
    return obj;
  }
}
