import { Injectable, Injector } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  settings: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isAppdataLoading: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(private injector: Injector,
              private http: HttpClient) {
  }

  getCMSSettings() {
    return this.http.get(environment.apiUrl + 'manager_user/applications/v1/application_cms_settings/get_cms_settings/');
  }

}
