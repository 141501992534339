export const environment = {
  production: false,
  TBApiUrl: 'https://tripbucket.qa-aws.i2asolutions.com/api/v2/',
  apiUrl: 'https://pps-backend-stg.i2asolutions.com/api/',
  websocketUrl: 'wss://pps.qa-aws.i2asolutions.com/ws/',
  secretKey: '8q_TBUeTlJeRxIWlYdpDwFjpq2jBQ6sKTyTJSunkbMs',
  deviceType: 'Web',
  externalAppId: 'Restaurant',
  externalAppIdTB: '7'
};
