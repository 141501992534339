import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  tokenChecked: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  login(data) {
    return this.http.post(environment.TBApiUrl + 'pps/login/', data);
  }

  me() {
    return this.http.get(environment.apiUrl + 'any/common/v1/get_me/');
  }

  changePassword(data) {
    return this.http.post(environment.TBApiUrl + '/pps/user/change_password/', data)
  }

  logout() {
    this.http.post(environment.TBApiUrl + 'pps/logout/', {}).subscribe(() => {
      this.logoutAction();
      this.toastr.success('Logged out successfully');
    }, error => {
      this.router.navigate(['/account/login']);
    });
  }

  logoutAction(disableRedirect?) {
    localStorage.removeItem('auth');
    localStorage.removeItem('uuid');
    this.user.next(null);
    this.router.navigate(['/account/login']);
  }

  convertAuthHeadersToToken() {
    return this.http.get(environment.apiUrl + 'any/common/v1/convert_auth_headers_to_token/');
  }

}
